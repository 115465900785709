<template>
    <div>
        <v-toolbar>
        <v-card-title>Create A Booking</v-card-title> 
        <v-spacer></v-spacer>
        <v-chip v-if="portOfLoading" outlined label class="ml-2 mr-2">
          <v-avatar size="24" class="mr-1" left>
            <v-img v-if="portOfLoading.countryCode"
              contain 
              :src="`https://cdn.loglive.io/flags/4x3/${portOfLoading.countryCode.toLowerCase()}.svg`">
            </v-img>
          </v-avatar>
          {{ portOfLoading.locode }}
        </v-chip>
        <v-icon v-if="portOfLoading && portOfDischarge">east</v-icon>
        <v-chip v-if="portOfDischarge" outlined label class="ml-2 mr-5">
          <v-avatar size="24" class="mr-1" left>
            <v-img v-if="portOfDischarge.countryCode"
              contain 
              :src="`https://cdn.loglive.io/flags/4x3/${portOfDischarge.countryCode.toLowerCase()}.svg`">
            </v-img>
          </v-avatar>
          {{ portOfDischarge.locode }}
        </v-chip>
        <v-chip :color="bookingType == 'transportOrder' ? 'orange' : 'edit'" outlined class="mr-4" >
            <span v-if="bookingType == 'transportOrder'"><v-icon>local_shipping</v-icon> Transport Order</span>
            <span v-if="bookingType == 'shippingOrder'"><v-icon>directions_boat</v-icon> Shipment Order</span>
        </v-chip>
        <v-btn color="red" icon @click="$emit('close')"><v-icon large>close</v-icon></v-btn>
        </v-toolbar>
        <v-stepper
         v-model="steps"
         vertical
        >
        <v-card-subtitle>Booking</v-card-subtitle> 
        <v-stepper-step :complete="steps > 1" :editable="maxStep >= 1"  step="1">
            <span>Booking Details</span>
            <small>Booking Type, Reference Number & Collection Date</small>
        </v-stepper-step>
            <v-stepper-content step="1">
                <v-card tile style="background-color: #303030 !important">
                    <v-row>
                        <v-col cols="12" sm="4" md="4" lg="4">
                            <v-card-subtitle class="pb-0 mb-0"><b>Order Type</b></v-card-subtitle>
                            <v-radio-group v-model="bookingType" class="ml-4">
                                <v-radio color="edit" key="1" value="shippingOrder"><template v-slot:label><div><v-icon>directions_boat</v-icon> Shipment Order</div></template> </v-radio> 
                                <v-radio color="orange" key="2" value="transportOrder"><template v-slot:label><div><v-icon>local_shipping</v-icon> Transport Order</div></template> </v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12" sm="4" md="4" lg="4" >
                            <v-card-subtitle class="pb-0 mb-0"><b>Order Number</b></v-card-subtitle>
                            <v-text-field v-model="orderNumber" label="Order Number" prepend-inner-icon="tag" placeholder="ex: M12345" persistent-placeholder outlined  style="width: 90%; margin: 2vh 0 0 1vw"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4" lg="4">
                            <v-card-subtitle class="pb-0 mb-0"><b>Collection Date</b></v-card-subtitle>
                            <v-menu
                                v-model="dateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="250px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="collectionDate"
                                        label="Collection Date"
                                        persistent-placeholder
                                        placeholder="YYYY-MM-DD"
                                        prepend-inner-icon="event"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        style="width: 45%; margin: 2vh 0 0 1vw; display:inline-block"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="collectionDate" @input="dateMenu == false"></v-date-picker>
                            </v-menu>
                            <v-menu
                                v-model="timeMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="250px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="collectionTime"
                                        label="Collection Time"
                                        persistent-placeholder
                                        placeholder="HH:MM"
                                        prepend-inner-icon="schedule"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        style="width: 35%; margin: 2vh 0 0 0; display:inline-block"
                                    ></v-text-field>
                                </template>
                                <v-time-picker format="24hr" v-model="collectionTime" @input="timeMenu == false"></v-time-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <div style="display: flex; justify-content: flex-end; "><v-btn color="success" outlined @click="steps++ && maxStep++">NEXT</v-btn></div>
                </v-card>
            </v-stepper-content>
        
        <v-stepper-step :complete="steps > 2" :editable="maxStep >= 2" step="2">
            <span>Parties Involved </span>
            <small>Shipper, Forwarder, Consignee, Notify-Party</small>
        </v-stepper-step>
            <v-stepper-content step="2">
                <v-row>
                <v-col cols="12" sm="3" md="3" lg="3">
                    <v-autocomplete 
                    label="Shipper"
                    v-model="shipper" 
                    prepend-inner-icon="directions_boat"
                    placeholder="Search for a Shipper"
                    persistent-placeholder 
                    @focus="focused = 'shipper'"
                    @blur="focused = null"
                    hide-no-data
                    clearable
                    return-object
                    :loading="searchingShippers"
                    :search-input.sync="searchShipper"
                    :menu-props="{ closeOnContentClick: true }"
                    :items="shipperOrgs.data"
                    item-value="id"
                    item-text="name"
                    >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                    </v-autocomplete>
                    <v-text-field persistent-placeholder v-if="shipper" label="Shipper Reference Number"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3" lg="3">
                    <v-autocomplete
                    label="Forwarder"
                    v-model="forwarder" 
                    prepend-inner-icon="fast_forward" 
                    placeholder="Search for a Forwarder"
                    persistent-placeholder
                    @focus="focused = 'forwarder'"
                    @blur="focused = null"
                    hide-no-data
                    clearable
                    return-object
                    :loading="searchingForwarders"
                    :search-input.sync="searchForwarder"
                    :menu-props="{ closeOnContentClick: true }"
                    :items="forwarderOrgs.data"
                    item-value="id"
                    item-text="name"
                     >
                     <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                    </v-autocomplete>
                    <v-text-field persistent-placeholder v-if="forwarder" label="Forwarder Reference Number"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3" lg="3">
                    <v-autocomplete 
                    label="Consignee"
                    v-model="consignee" 
                    prepend-inner-icon="call_received" 
                    placeholder="Search for a Consignee"
                    persistent-placeholder 
                    @focus="focused = 'consignee'"
                    @blur="focused = null"
                    hide-no-data
                    clearable
                    return-object
                    :loading="searchingConsignees"
                    :search-input.sync="searchConsignee"
                    :menu-props="{ closeOnContentClick: true }"
                    :items="consigneeOrgs.data"
                    item-value="id"
                    item-text="name"
                    >
                    <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                </v-autocomplete>
                    <v-text-field persistent-placeholder v-if="consignee" label="Consignee Reference Number"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3" lg="3">
                    <v-autocomplete 
                    label="Notify Party"
                    v-model="notifyParty" 
                    prepend-inner-icon="notifications_active" 
                    placeholder="Search for a Notify Party"
                    persistent-placeholder 
                    @focus="focused = 'notifyParty'"
                    @blur="focused = null"
                    hide-no-data
                    clearable
                    return-object
                    :loading="searchingNotifyParty"
                    :search-input.sync="searchNotifyParty"
                    :menu-props="{ closeOnContentClick: true }"
                    :items="notifyPartyOrgs.data"
                    item-value="id"
                    item-text="name"
                    >
                    <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                </v-autocomplete>
                    <v-text-field persistent-placeholder v-if="notifyParty" label="Notify Party Reference Number"></v-text-field>
                </v-col>
            </v-row>
            <div style="display: flex; justify-content: flex-end;"><v-btn color="success" outlined @click="steps++ && maxStep++">NEXT</v-btn></div>
            </v-stepper-content>

        <v-stepper-step :complete="steps > 3" :editable="maxStep >= 3" step="3">
            <span>Origin & Destination</span>
            <small>Port of Loading & Port of Discharge</small>
        </v-stepper-step>
            <v-stepper-content step="3">
                <v-row>
                    <v-col cols="12" md="6" sm="6" lg="6">
                        <v-subheader class="pb-0 mb-0"><b>Origin</b></v-subheader>
                            <v-autocomplete
                            class="countrySelect"
                            ref="originCountry"
                            hide-no-data
                            hide-selected
                            label="Country of Origin"
                            persistent-placeholder
                            placeholder="Select a Country of Origin"
                            outlined
                            clearable
                            dense
                            :items="countriesArray"
                            item-text="name"
                            item-value="id"
                            :menu-props="{ closeOnContentClick: true }"
                            return-object
                            @input="displayPOL = true"
                            v-model="originCountry"
                            >
                                <template v-slot:selection="data">
                                    <v-avatar size="32" class="mr-2" v-if="data.item">
                                        <v-img
                                        v-if="data.item.iso2"
                                        contain
                                        :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"
                                  ></v-img>
                                </v-avatar>
                                <span v-if="data.item">
                                    {{ data.item.name }}
                                </span>
                                </template>
                                <template v-slot:item="data">
                                    <v-list-item-avatar>
                                        <v-img
                                        contain
                                        v-if="data.item.iso2"
                                        :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"
                                        ></v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title
                                        >{{ data.item.name }}</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-autocomplete>
                        <div v-if="originCountry">
                            <v-subheader class="pb-0 mb-0">
                                <b>Port of Loading </b>
                                <v-chip v-if="portOfLoading" outlined label close @click:close="portOfLoading = null" class="ml-2">
                                  <v-avatar size="24" class="mr-1" left>
                                    <v-img v-if="portOfLoading.countryCode"
                                      contain 
                                      :src="`https://cdn.loglive.io/flags/4x3/${portOfLoading.countryCode.toLowerCase()}.svg`">
                                    </v-img>
                                  </v-avatar>
                                  {{ portOfLoading.locode }}
                                </v-chip>
                                <v-spacer></v-spacer>
                                <v-text-field
                                    style="width: 30%"
                                    class="mb-0 pb-0"
                                    prepend-inner-icon="search" 
                                    dense 
                                    label="Search"
                                    v-model="searchOrigin"
                                    >
                                </v-text-field>
                                <v-btn v-if="originCountry && !displayPOL"
                                    icon 
                                    @click="displayPOL = true"
                                    >
                                    <v-icon>expand_more</v-icon>
                                </v-btn>
                                <v-btn v-else-if="originCountry && displayPOL"
                                    icon 
                                    @click="displayPOL = false">
                                    <v-icon>expand_less</v-icon>
                                </v-btn>
                            </v-subheader>
                            <v-divider></v-divider>
                            <div v-if="loadingOriginPorts" style="display: flex; justify-content: center; margin-top: 4vh">
                                <v-progress-circular indeterminate></v-progress-circular>
                            </div>
                            <div v-if="noOriginPorts" style="display: flex; justify-content: center; margin-top: 2vh">
                                <span style="font-style: italic;">No Ports were found...</span>
                            </div>
                            <div v-if="!loadingOriginPorts && !noOriginPorts">
                                <v-expand-transition>
                                    <v-virtual-scroll v-if="displayPOL"
                                        :bench="0" 
                                        :items="filterOriginPort"
                                        style="height: 20vh; overflow-y: auto" height="250" 
                                        item-height="60"
                                    >
                                        <template v-slot:default="{ item }">
                                            <v-list-item 
                                                :key="item.id"
                                                @click="portOfLoading = item, 
                                                        portOfLoading && portOfDischarge ? stepper = 2 : stepper = 1,
                                                        displayPOL = false,
                                                        updateKey++,
                                                        searchKey++"
                                            >
                                                <v-list-item-avatar>
                                                  <v-avatar size="24" class="mr-1" left>
                                                    <v-img contain v-if="item.locode"
                                                      :src="`https://cdn.loglive.io/flags/4x3/${item.locode.substring(0, 2).toLowerCase()}.svg`">
                                                    </v-img>
                                                  </v-avatar>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                  <v-list-item-title>
                                                    {{ item.name }}
                                                  </v-list-item-title>
                                                  <v-list-item-subtitle>
                                                    {{ item.locode }}
                                                  </v-list-item-subtitle>
                                                </v-list-item-content>
                                          </v-list-item>
                                        </template>
                                    </v-virtual-scroll>
                                </v-expand-transition>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" sm="6" lg="6">
                        <v-subheader class="pb-0 mb-0"><b>Destination</b></v-subheader>
                        <v-autocomplete
                        class="countrySelect"
                        ref="destinationCountry"
                        hide-no-data
                        hide-selected
                        label="Destination Country"
                        persistent-placeholder
                          placeholder="Select a Destination Country"
                          outlined
                          clearable
                          dense
                          :items="countriesArray"
                          item-text="name"
                          item-value="id"
                          :menu-props="{ closeOnContentClick: true }"
                          return-object
                          @input="displayPOD = true"
                          v-model="destinationCountry"
                        >
                          <template v-slot:selection="data">
                            <v-avatar size="32" class="mr-2" v-if="data.item">
                              <v-img
                                v-if="data.item.iso2"
                                contain
                                :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"
                              ></v-img>
                            </v-avatar>
                            <span v-if="data.item">
                              {{ data.item.name }}
                            </span>
                          </template>
                          <template v-slot:item="data">
                            <v-list-item-avatar>
                              <v-img
                                contain
                                v-if="data.item.iso2"
                                :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"
                              ></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title
                              >{{ data.item.name }}</v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                        <div v-if="destinationCountry">
                            <v-subheader class="pb-0 mb-0">
                                <b>Port of Discharge </b>
                                <v-chip v-if="portOfDischarge" outlined label close @click:close="portOfDischarge = null" class="ml-2">
                                  <v-avatar size="24" class="mr-1" left>
                                    <v-img v-if="portOfDischarge.countryCode"
                                      contain 
                                      :src="`https://cdn.loglive.io/flags/4x3/${portOfDischarge.countryCode.toLowerCase()}.svg`">
                                    </v-img>
                                  </v-avatar>
                                  {{ portOfDischarge.locode }}
                                </v-chip>
                                <v-spacer></v-spacer>
                                <v-text-field
                                    style="width: 30%"
                                    class="mb-0 pb-0"
                                    prepend-inner-icon="search" 
                                    dense 
                                    label="Search"
                                    v-model="searchDestination"
                                    >
                                </v-text-field>
                                <v-btn v-if="destinationCountry && !displayPOD"
                                    icon 
                                    @click="displayPOD = true"
                                    >
                                    <v-icon>expand_more</v-icon>
                                </v-btn>
                                <v-btn v-else-if="destinationCountry && displayPOD"
                                    icon 
                                    @click="displayPOD = false">
                                    <v-icon>expand_less</v-icon>
                                </v-btn>
                            </v-subheader>
                            <v-divider></v-divider>
                            <div v-if="loadingDestinationPorts" style="display: flex; justify-content: center; margin-top: 4vh">
                                <v-progress-circular indeterminate></v-progress-circular>
                            </div>
                            <div v-if="noDestinationPorts" style="display: flex; justify-content: center; margin-top: 2vh">
                                <span style="font-style: italic;">No Ports were found...</span>
                            </div>
                            <div v-if="!loadingDestinationPorts && !noDestinationPorts">
                                <v-expand-transition>
                                    <v-virtual-scroll v-if="displayPOD"
                                        :bench="0" 
                                        :items="filterDestinationPort"
                                        style="height: 20vh; overflow-y: auto" height="250" 
                                        item-height="60"
                                    >
                                        <template v-slot:default="{ item }">
                                            <v-list-item 
                                                :key="item.id"
                                                @click="portOfDischarge = item,
                                                        portOfDischarge && portOfDischarge ? stepper = 2 : stepper = 1,
                                                        displayPOD = false,
                                                        updateKey++,
                                                        searchKey++,
                                                        consoleThis(item)"
                                            >
                                                <v-list-item-avatar>
                                                  <v-avatar size="24" class="mr-1" left>
                                                    <v-img contain v-if="item.locode"
                                                      :src="`https://cdn.loglive.io/flags/4x3/${item.locode.substring(0, 2).toLowerCase()}.svg`">
                                                    </v-img>
                                                  </v-avatar>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                  <v-list-item-title>
                                                    {{ item.name }}
                                                  </v-list-item-title>
                                                  <v-list-item-subtitle>
                                                    {{ item.locode }}
                                                  </v-list-item-subtitle>
                                                </v-list-item-content>
                                          </v-list-item>
                                        </template>
                                    </v-virtual-scroll>
                                </v-expand-transition>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            <div style="display: flex; justify-content: flex-end;"><v-btn color="success" outlined @click="steps++ && maxStep++">NEXT</v-btn></div>
            </v-stepper-content>

        <v-stepper-step v-if="bookingType == 'shippingOrder'" :editable="maxStep >= 4" :complete="steps > 4" step="4">
            <span> Vessel & Voyage</span>
            <small></small>
        </v-stepper-step>
            <v-stepper-content step="4">
            </v-stepper-content>

        <v-stepper-step :complete="bookingType == 'shippingOrder' ? steps > 5 : steps > 4 " :editable="bookingType == 'shippingOrder' ? maxStep >= 5 : maxStep >=4" :step="bookingType == 'shippingOrder' ? 5 : 4">
            Cargo Details
        </v-stepper-step>
            <v-stepper-content :step="bookingType == 'shippingOrder' ? 5 : 4">
                <v-row>
                    <v-col cols="12" sm="4" md="4" lg="4">
                        <el-input-number 
                            :min="1" 
                            size="mini"
                            :value="containerItem.quantity" 
                            v-model="containerItem.quantity">
                    </el-input-number>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4">
                        <v-autocomplete 
                            outlined 
                            dense 
                            clearable 
                            label="Container Type" 
                            v-model="containerItem.containerTypeCode"
                            :items="containerTypes" 
                            item-text="shortDescription" 
                            item-value="typeCode">
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4">
                        <v-autocomplete 
                        outlined 
                        dense 
                        clearable 
                        label="Regime Code" 
                        v-model="containerItem.regimeCode"
                        :items="regimeCodes" 
                        item-text="code" 
                        item-value="code"
                        >
                            <template v-slot:selection="data">
                              <span v-if="data.item">
                                {{ data.item.code }}
                              </span>
                            </template>
                            <template v-slot:item="data">
                              <v-list-item-content>
                                <v-list-item-title>{{ data.item.code }}</v-list-item-title>
                                <v-list-item-subtitle class="text-wrap">{{ data.item.recipeDescription }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4">
                        <v-row>
                            <v-col cols="12" sm="6" md="6" lg="6">
                                <v-text-field 
                                    label="Airflow" 
                                    outlined 
                                    dense 
                                    v-model="containerItem.airFlowValue"
                                    suffix="CBM">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" lg="6">
                                <v-text-field  
                                    prepend-inner-icon="thermostat" 
                                    label="Temp" 
                                    v-model="containerItem.temperature"
                                    outlined 
                                    dense 
                                    suffix="°C">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-list-item>
                          <v-list-item-action>
                            <v-icon>bolt</v-icon>
                          </v-list-item-action>
                          <v-list-item-content class="text-wrap">
                            <v-list-item-title> Genset Required </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-simple-checkbox color="primary" :ripple="false"
                              v-model="containerItem.gensetRequired"></v-simple-checkbox>
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-action>
                            <v-icon>thermostat_auto</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title class="text-wrap">
                              Controlled Atmosphere
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-simple-checkbox color="primary" :ripple="false"
                              v-model="containerItem.controlledAtmosphere"></v-simple-checkbox>
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-action>
                            <v-icon>air</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Vents </v-list-item-title>
                            <v-list-item-subtitle>
                              {{
                                containerItem.ventOpened ? "Vents Open" : "Vents Closed"
                              }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-switch color="success" :error="!containerItem.ventOpened"
                              v-model="containerItem.ventOpened"></v-switch>
                          </v-list-item-action>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4">
                        <v-text-field 
                            label="Oxygen" 
                            v-model="containerItem.oxygen" 
                            outlined 
                            dense
                            suffix="%">
                        </v-text-field>
                        <v-text-field 
                            label="Carbon Dioxide" 
                            v-model="containerItem.carbonDioxide" 
                            outlined 
                            dense
                            suffix="%">
                        </v-text-field>
                        <v-text-field 
                            label="Nitrogen" 
                            v-model="containerItem.nitrogen" 
                            outlined 
                            dense
                            suffix="%">
                        </v-text-field>
                        <v-text-field 
                            label="Humidity" 
                            v-model="containerItem.humidity" 
                            outlined 
                            dense
                            suffix="%">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4">
                        <v-list dense subheader v-if="containerItem.bookingCargoItems">
                            <v-subheader style="font-size: 16px">Products
                              <v-btn icon color="primary" @click="addContainerCargoDetails">
                                <v-icon>add_circle_outline</v-icon>
                            </v-btn>
                            </v-subheader>
                            <v-list-item v-if="containerItem.bookingCargoItems.length == 0">
                              <v-list-item-content class="text-center">
                                <span style="color: grey">No products listed</span>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-for="cargo in containerItem.bookingCargoItems" :key="cargo.index">
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ cargo.product.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle class="text-wrap">
                                  {{ cargo.hsCode }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="cargo.weight || cargo.volume" class="text-wrap">
                                  {{ cargo.weight }} Kg - {{ cargo.volume }} CBM
                                </v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-row justify="center">
                                  <v-btn icon small @click="editCargoItem(cargo)"><v-icon small>edit</v-icon></v-btn>
                                  <v-btn icon small @click="deleteCargoItem(cargo)" color="red"><v-icon
                                      small>delete</v-icon></v-btn>
                                </v-row>
                              </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12" sm="12" lg="12">
                        <v-textarea v-model="containerItem.comments" label="Comments" outlined rows="3"></v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                  <v-list>

                  </v-list>
                </v-row>
            </v-stepper-content>

        <v-stepper-step :complete="bookingType == 'shippingOrder' ? steps > 6 : steps > 5 " :editable="bookingType == 'shippingOrder' ? maxStep >= 6 : maxStep >=5" :step="bookingType == 'shippingOrder' ? 6 : 5">
            Empty Pickup
        </v-stepper-step>
            <v-stepper-content :step="bookingType == 'shippingOrder' ? 6 : 5">
            </v-stepper-content>

        <v-stepper-step :complete="bookingType == 'shippingOrder' ? steps > 7 : steps > 6 " :editable="bookingType == 'shippingOrder' ? maxStep >= 7 : maxStep >=6" :step="bookingType == 'shippingOrder' ? 7 : 6">
            Loading-Points
        </v-stepper-step>
            <v-stepper-content :step="bookingType == 'shippingOrder' ? 7 : 6">
            </v-stepper-content>

        <v-stepper-step :complete="bookingType == 'shippingOrder' ? steps > 8 : steps > 7 " :editable="bookingType == 'shippingOrder' ? maxStep >= 8 : maxStep >=7" :step="bookingType == 'shippingOrder' ? 8 : 7">
            <span>Telemetry</span>
            <small>Temp, Vent & Genset Settings</small>
        </v-stepper-step>
            <v-stepper-content :step="bookingType == 'shippingOrder' ? 8 : 7">
            </v-stepper-content>

        </v-stepper>


    <v-dialog v-model="cargoModal" persistent width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Cargo Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(cargoItem = {}), (cargoModal = false)">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-select label="Type" :items="['Product Group', 'Product', 'Variety']" v-model="filterProductType" outlined
            dense></v-select>
          <v-autocomplete ref="countrySelect" hide-no-data hide-selected label="Product" dense outlined
            :items="filterProducts" clearable item-text="name" item-value="id" :menu-props="{ closeOnContentClick: true }"
            @change="setCargoItemDetails" v-model="cargoItem.product" return-object :loading="loadingProduct">
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.item.type }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="data.item.parentProduct" style="font-size: 12px">
                  Parent: {{ data.item.parentProduct.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-textarea rows="3" label="Description" v-model="cargoItem.description" outlined></v-textarea>
          <v-text-field label="HS Code" v-model="cargoItem.hsCode" outlined dense></v-text-field>
          <v-text-field label="Cargo Weight (Excl. Tare)" suffix="Kg" v-model="cargoItem.weight" outlined
            dense></v-text-field>
          <v-text-field label="Gross Volume" suffix="CBM" v-model="cargoItem.volume" outlined dense></v-text-field>
          <v-row class="mt-1" justify="center">
            <v-btn text color="primary" :disabled="
              !cargoItem.product ||
              !cargoItem.hsCode ||
              !cargoItem.description
            " @click="saveCargoItem">Save</v-btn>
          </v-row>
        </v-card-text>
      </v-card></v-dialog>
    </div>
</template>

<script>

export default {
    props: {},
    components: {},
    data: () => ({
        countriesArray: [],
        allOriginPorts: [],
        allDestinationPorts: [],
        steps: 1,
        maxStep: 0,
        updateKey: 0,
        searchKey: 0,
        cargoKey: 0,
        bookingType: 'transportOrder',
        filterProductType: "Product",
        orderNumber: null,
        collectionDate: null,
        collectionTime: null,
        date: null,
        timeMenu: false,
        dateMenu: false,
        loadingOriginPorts: false,
        noOriginPorts: false,
        loadingDestinationPorts: false,
        noDestinationPorts: false,
        loadingProduct: false,
        shipper: null,
        forwarder: null,
        consignee: null,
        notifyParty: null,
        focused: null,
        displayPOL: false,
        displayPOD: false,
        searchOrigin: null,
        searchDestination: null,
        searchingShippers: false,
        searchingForwarders: false,
        cargoModal: false,
        cargoItem: {},
        searchingConsignees: false,
        searchingNotifyParty: false,
        searchShipper: null,
        searchForwarder: null,
        searchConsignee: null,
        searchNotifyParty: null,
        shipperTimeout: null,
        forwarderTimeout: null,
        consigneeTimeout: null,
        notifyPartyTimeout: null,
        shipperOrgs: {total: 0, data: []},
        forwarderOrgs: {total: 0, data: []},
        consigneeOrgs: {total: 0, data: []},
        notifyPartyOrgs: {total: 0, data: []},
        portOfLoading: null,
        portOfDischarge: null,
        originCountry: null,
        destinationCountry:  null,
        containerItem: {
            quantity: 1,
            type: null,
            regimeCode: null,
            airflow: null,
            temperature: null,
            gensetRequired: false,
            ventOpened: false,
            controlledAtmosphere: false,
            oxygen: null,
            co2: null,
            nitrogen: null,
            humidity: null,
            bookingCargoItems: [],
            comments: null,
        },
        containerTypes: [],
        regimeCodes: [],
        products: {
          total: 0,
          data: [],
        },
    }),

    async created() {
        this.countriesArray = await this.$API.searchCountriesBasic({});
        this.containerTypes = await this.$API.listContainerTypes();
        this.regimeCodes = await this.$API.getRegimeCodes();
        this.products = await this.$API.getProducts({});

    },
    computed: {
        filterOriginPort() {
          let result = this.allOriginPorts
          if (this.searchOrigin) {
            result = result.filter(x => x.name.toLowerCase().includes(this.searchOrigin.toLowerCase()) || x.locode.toLowerCase().includes(this.searchOrigin.toLowerCase()))
          }
          return result
        },
        filterDestinationPort() {
          let result = this.allDestinationPorts
          if (this.searchDestination) {
            result = result.filter(x => x.name.toLowerCase().includes(this.searchDestination.toLowerCase()) || x.locode.toLowerCase().includes(this.searchDestination.toLowerCase()))
          }
          return result
        },
        filterProducts() {
          let result = this.products.data.filter((x) => x.hsCode);
          if (result && this.filterProductType) {
            result = result.filter((x) => x.type == this.filterProductType);
          }
          return result;
        },
    },

    watch: {
    async searchShipper(val) {
      if (this.searchingShippers) {
        clearTimeout(this.shipperTimeout);
      }
      if (val && val.length > 2) {
        this.shipperTimeout = setTimeout(async () => {
          this.searchingShippers = true;
          this.shipperOrgs = await this.$API.getOrganisationsPOI({
            params: {
              search: val,
              limit: 15,
            },
          });
          this.searchingShippers = false;
        }, 150);
        this.searchingShippers = false;
      } else {
        clearTimeout(this.shipperTimeout);
        this.searchingShippers = false;
      }
    },
    async searchForwarder(val) {
      if (this.searchingForwarders) {
        clearTimeout(this.forwarderTimeout);
      }
      if (val && val.length > 2) {
        this.forwarderTimeout = setTimeout(async () => {
          this.searchingForwarders = true;
          this.forwarderOrgs = await this.$API.getOrganisationsPOI({
            params: {
              search: val,
              limit: 15,
            },
          });
          this.searchingForwarders = false;
        }, 150);
        this.searchingForwarders = false;
      } else {
        clearTimeout(this.forwarderTimeout);
        this.searchingForwarders = false;
      }
    },
    async searchConsignee(val) {
      if (this.searchingConsignees) {
        clearTimeout(this.consigneeTimeout);
      }
      if (val && val.length > 2) {
        this.consigneeTimeout = setTimeout(async () => {
          this.searchingConsignees = true;
          this.consigneeOrgs = await this.$API.getOrganisationsPOI({
            params: {
              search: val,
              limit: 15,
            },
          });
          this.searchingConsignees = false;
        }, 150);
        this.searchingConsignees = false;
      } else {
        clearTimeout(this.consigneeTimeout);
        this.searchingConsignees = false;
      }
    },
    async searchNotifyParty(val) {
      if (this.searchingNotifyParty) {
        clearTimeout(this.notifyPartyTimeout);
      }
      if (val && val.length > 2) {
        this.notifyPartyTimeout = setTimeout(async () => {
          this.searchingNotifyParty = true;
          this.notifyPartyOrgs = await this.$API.getOrganisationsPOI({
            params: {
              search: val,
              limit: 15,
            },
          });
          this.searchingNotifyParty = false;
        }, 150);
        this.searchingNotifyParty = false;
      } else {
        clearTimeout(this.notifyPartyTimeout);
        this.searchingNotifyParty = false;
      }
    },

    'originCountry': {
        immediate: true,
        async handler(val) {
            if(val) {
            this.loadingOriginPorts = true
            this.noOriginPorts = false
            this.allOriginPorts = await this.$API.getPortsByCountry(this.originCountry.iso2)
            if(this.allOriginPorts.length == 0) {
                this.loadingOriginPorts = false
                this.noOriginPorts = true
            } else {
                this.loadingOriginPorts = false
                this.noOriginPorts = false
            }
            }
        }
    },
    'destinationCountry' : {
        immediate: true,
        async handler(val) {
            if(val) {
            this.loadingDestinationPorts = true
            this.noDestinationPorts = false
            this.allDestinationPorts = await this.$API.getPortsByCountry(this.destinationCountry.iso2)
            if(this.allDestinationPorts.length == 0) {
                this.loadingDestinationPorts = false
                this.noDestinationPorts = true
            } else {
                this.loadingDestinationPorts = false
                this.noDestinationPorts = false
            }
        }
    },
    }
    },

    methods: {
      consoleThis(data) {
          console.log(data)
      },

      addContainerCargoDetails() {
        this.cargoItem = {
          type: 'container',
          index: this.containerItem.bookingCargoItems.length,
        };
        this.cargoModal = true;
      },

      setCargoItemDetails() {
        if (this.cargoItem.product) {
          this.cargoItem.description = this.cargoItem.product.hsCode.explanation;
          this.cargoItem.hsCode = this.cargoItem.product.hsCode.alternateCode.replace(
            / /g,
            ""
          );
          this.cargoItem.productId = this.cargoItem.product.id;
        }
      },
      
      async loadProducts() {
        this.loadingProduct = true;
        this.products = await this.$API.getProducts({});
        this.loadingProduct = false;
      },

      saveCargoItem() {
        if (this.cargoItem.type == "container") {
          let find = this.containerItem.bookingCargoItems.findIndex(
            (x) => x.index === this.cargoItem.index
          );
          this.cargoItem.productId = this.cargoItem.product.id;
          this.cargoItem.hsCodeId = this.cargoItem.hsCode.id;
        
          if (find > -1) {
            this.containerItem.bookingCargoItems[find] = this.cargoItem;
          } else {
            this.containerItem.bookingCargoItems.push(this.cargoItem);
          }
        }
        this.cargoKey++;
        this.cargoModal = false;
        this.cargoItem = {};
      },

      editCargoItem(item) {
        console.log(this.containerItem.bookingCargoItems)
        this.cargoItem = item;
        this.cargoModal = true;
      },

      deleteCargoItem(item) {
      this.containerItem.bookingCargoItems.splice(item.index, 1);
    },
    }
}

</script>

<style scoped>

.countrySelect {
    width: 60%;
    left: 20%;
    margin-top: 1vh
}
</style>